<template>
  <div
    class="ag-theme-material ag-theme-modern"
    style="width: 100%; flex: 1 1 auto; min-height: 50%"
    ref="baseTable"
  >
    <ag-grid-vue
      v-if="columnDefs"
      :style="gridStyle"
      :rowHeight="rowHeight"
      :defaultColDef="defaultColDef"
      :columnDefs="columnDefs"
      :gridOptions="gridOptions"
      :rowData="row"
      :getRowId="getRowId"
      :enableRangeSelection="true"
      :enableCharts="true"
      :sideBar="sideBar"
      :statusBar="statusBar"
      :rowGroupPanelShow="rowGroupPanelShow"
      :groupIncludeFooter="globalSettings.groupIncludeFooter"
      :groupIncludeTotalFooter="globalSettings.groupIncludeTotalFooter"
      :headerHeight="globalSettings.headerHeight"
      :domLayout="domLayout"
      :debounceVerticalScrollbar="debounceVerticalScrollbar"
      :suppressRowVirtualisation="suppressRowVirtualisation"
      :suppressColumnVirtualisation="suppressColumnVirtualisation"
      :suppressMaxRenderedRowRestriction="suppressMaxRenderedRowRestriction"
      :pagination="pagination"
      :paginationPageSize="paginationPageSize"
      rowSelection="single"
      @grid-ready="onGridReady"
      @first-dataRendered="onFirstDataRendered"
      @body-scroll-end="onBodyScroll"
      @modelUpdated="somethingChanged"
      :overlayLoadingTemplate="loadingTemplate"
      :overlayNoRowsTemplate="noRowsTemplate"
    >
    </ag-grid-vue>
  </div>
</template>
<script>
import { AgGridVue } from "@ag-grid-community/vue3";
import { ModuleRegistry } from "@ag-grid-community/core";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
import { GridChartsModule } from "@ag-grid-enterprise/charts";
import { ClipboardModule } from "@ag-grid-enterprise/clipboard";
import { ExcelExportModule } from "@ag-grid-enterprise/excel-export";
import { RangeSelectionModule } from "@ag-grid-enterprise/range-selection";
import { RichSelectModule } from "@ag-grid-enterprise/rich-select";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import { MultiFilterModule } from "@ag-grid-enterprise/multi-filter";
import { SideBarModule } from "@ag-grid-enterprise/side-bar";
import { StatusBarModule } from "@ag-grid-enterprise/status-bar";

import EntityApi from "@/api/entityApi";

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  MenuModule,
  ColumnsToolPanelModule,
  FiltersToolPanelModule,
  GridChartsModule,
  ClipboardModule,
  ExcelExportModule,
  RangeSelectionModule,
  RichSelectModule,
  RowGroupingModule,
  SetFilterModule,
  MultiFilterModule,
  SideBarModule,
  StatusBarModule,
]);
import { LicenseManager } from "@ag-grid-enterprise/core";
LicenseManager.setLicenseKey(
  "CompanyName=Modern-Expo,LicensedGroup=Oleksandr Astafiev,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-026354,ExpiryDate=17_March_2023_[v2]_MTY3OTAxMTIwMDAwMA==75d0e08ce366f10efb524c0b51434bc4"
);

// import "ag-grid-community/styles/ag-grid.css";
// import "ag-grid-community/styles/ag-theme-alpine.css";

import DateRenderer from "./tableComponents/DateRenderer.vue";
import BoolRenderer from "./tableComponents/BoolRenderer.vue";
import BoolRenderer_round from "./tableComponents/BoolRenderer_round.vue";
import ObjectRenderer from "./tableComponents/ObjectRenderer.vue";
import FileRenderer from "./tableComponents/FileRenderer.vue";
import RowMenuCellRenderer from "./tableComponents/rowMenuCellRenderer.vue";
export default {
  name: "Table",
  props: {
    row: {
      type: Array,
      required: true,
    },
    countAllRows: {
      type: Number,
      required: false,
    },
    countRowsLoaded: {
      type: Number,
      default: 50,
    },
    rowHeight: {
      type: Number,
      default: 50,
    },
    columnDefs: {
      type: [Array, null],
      required: true,
    },
    settings: {
      type: Object,
    },
    tableType: {
      type: String,
    },
    cardType: {
      type: String,
      default: "edit",
    },
    statusBar: {
      type: Object,
      required: false,
    },
    rowGroupPanelShow: {
      type: String,
      default: "never",
    },
    loadNextPage: {
      type: Boolean,
      default: false,
    },
    entity: {
      type: String,
      required: false,
    },
    pagination: {
      type: Boolean,
      default: false,
    },
    paginationPageSize: {
      type: Number,
      default: 30,
    },
    startRowsLoaded: {
      type: Number,
      default: 100,
    },
    paramsRequest: {
      type: Object,
      required: false,
      default: null,
    },
    entityPermission: {
      type: Object,
    },
    height: {
      type: Number,
      default: 0,
    },
  },
  components: {
    AgGridVue,
    // eslint-disable-next-line vue/no-unused-components
    fileRenderer: FileRenderer,
    // eslint-disable-next-line vue/no-unused-components
    boolRenderer: BoolRenderer,
    // eslint-disable-next-line vue/no-unused-components
    boolRendRounded: BoolRenderer_round,
    // eslint-disable-next-line vue/no-unused-components
    dateRenderer: DateRenderer,
    // eslint-disable-next-line vue/no-unused-components
    objectRenderer: ObjectRenderer,
    // eslint-disable-next-line vue/no-unused-components
    rowMenuCellRenderer: RowMenuCellRenderer,
  },
  // inject: ["refreshRow"],
  emits: [
    "showRowBtnClick",
    "editRowBtnClick",
    "deleteRowBtnClick",
    "subtableEditRowBtnClick",
    "subtableDeleteRowBtnClick",
    "subtableShowRowBtnClick",
  ],
  data() {
    return {
      gridApi: null,
      columnApi: null,
      gridOptions: {
        context: {
          instance: this,
          tabletype: "primary",
        },
      },
      defaultColDef: {
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        width: 100,
        flex: 1,
        minWidth: 20,
        filter: true,
        sortable: true,
        resizable: true,
      },
      getRowId: null,
      rowData: null,
      documentHeight: null,
      sideBar: {
        position: "rigth",
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
            minWidth: 250,
            width: 250,
            maxWidth: 250,
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
            minWidth: 250,
            maxWidth: 250,
            width: 250,
          },
        ],
      },
      globalSettings: {
        headerHeight: 40,
        groupIncludeFooter: false,
        groupIncludeTotalFooter: false,
        showGridControll: true,
      },
      domLayout: "",
      lastPage: 0,
      rowBuffer: 20,
      debounceVerticalScrollbar: false,
      suppressRowVirtualisation: true,
      suppressColumnVirtualisation: true,
      suppressMaxRenderedRowRestriction: true,
      lastIndex: 0,
    };
  },
  watch: {
    row() {
      this.rowData = this.row;
    },
    height() {
      this.applyGlobalSetings(this.settings);
    },
  },
  computed: {
    gridStyle() {
      if (this.documentHeight) {
        let gridHeight;
        if (!this.height && this.documentHeight > 500) {
          gridHeight = `${this.documentHeight - 245}px`;
        } else {
          gridHeight = `${this.documentHeight}px`;
        }
        return [{ width: "100%" }, { height: gridHeight }];
      } else {
        return [{ width: "100%" }, { minHeight: "30px" }];
      }
    },
    noRowsTemplate() {
      return `<span class="ag-overlay-loading-center">${this.$t(
        "receipt_analyz.label.no_data_table"
      )}</span>`;
    },
    loadingTemplate() {
      return `<span class="ag-overlay-loading-center">${this.$t(
        "lables.loading"
      )}</span>`;
    },
  },
  methods: {
    onFirstDataRendered(params) {
      if (this.row.length === 0) {
        params.api.showNoRowsOverlay();
      } else {
        params.api.hideOverlay();
      }
    },
    somethingChanged(params) {
      if (this.row.length === 0) {
        params.api.showNoRowsOverlay();
        //this.gridApi?.showNoRowsOverlay();
      } else {
        params.api.hideOverlay();
        //this.gridApi?.hideOverlay();
      }
    },
    onGridReady(params) {
      //params.api.showLoadingOverlay();
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      params.api.sizeColumnsToFit();

      //params?.api?.hideOverlay();
    },
    onRowSelected(event) {
      if (event.node.data) {
        // console.log("onRowSelected", event.node.data);
      }
    },
    async onBodyScroll(params) {
      if (this.loadNextPage && this.countAllRows > this.lastPage) {
        const bottom_px = params.api.getVerticalPixelRange().bottom;
        const row_h = params.api.getSizesForCurrentTheme().rowHeight;
        const grid_height = params.api.getDisplayedRowCount() * row_h;

        console.log("onBodyScroll", bottom_px, grid_height - 2 * row_h);

        if (bottom_px >= grid_height - 2 * row_h) {
          params.api.showLoadingOverlay();
          EntityApi.getEntityItemsLimit(
            this.entity,
            this.lastPage,
            this.countRowsLoaded,
            this.paramsRequest,
            true
          ).then((response) => {
            this.lastPage = this.lastPage + response.rows.length;
            this.lastIndex = this.rowData?.length + this.lastPage;
            params.api.applyTransaction({
              add: response.rows,
              addIndex: this.lastIndex,
            });
            params.api.hideOverlay();
          });
        }
      }
    },

    refreshRowData(row) {
      if (this.gridApi) {
        let rowNode = this.gridApi.getRowNode(row.id);
        rowNode.setData(row);
      }
    },
    addNewRow(row) {
      this.gridApi.applyTransaction({
        add: [{ ...row }],
        addIndex: null,
      });
    },
    refreshTableData() {
      if (this.gridApi) {
        this.gridApi.refreshCells();
      }
    },
    autosizeColumn() {
      if (this.gridApi) {
        this.gridApi.sizeColumnsToFit();
      }
    },
    filterTableData(column, string) {
      let filtreadColumn = this.gridApi.getFilterInstance(column);
      filtreadColumn.setModel({
        filterType: "text",
        type: "contains",
        filter: string,
      });
      this.gridApi.onFilterChanged();
    },
    setRowSelectead(id) {
      let self = this;
      function selectRow(id) {
        self.gridApi?.forEachNode((node) => {
          node.setSelected(node.data && node.data.id === id);
        });
        setTimeout(() => {
          self.scrollToSelectedRow();
        }, 200);
      }
      let count = 0;
      function callIfTrue() {
        if (self.gridApi) {
          selectRow(id);
        } else {
          if (count <= 10) {
            count++;
            setTimeout(() => {
              callIfTrue();
            }, 500);
          }
        }
      }
      callIfTrue();
    },
    scrollToSelectedRow() {
      if (this.gridApi.getSelectedNodes().length > 0) {
        this.gridApi.ensureIndexVisible(
          this.gridApi.getSelectedNodes()[0].rowIndex,
          "middle"
        );
      }
    },
    onEditRowEvent(row) {
      this.$emit("editRowBtnClick", row);
    },
    onDeleteRowEvent(row) {
      this.$emit("deleteRowBtnClick", row);
    },
    onShowRowData(row) {
      this.$emit("showRowBtnClick", row);
    },
    onSubtableEditRowEvent(row) {
      this.$emit("subtableEditRowBtnClick", row);
    },
    onSubtableDeleteRowEvent(row) {
      this.$emit("subtableDeleteRowBtnClick", row);
    },
    onSubtableShowRowEvent(row) {
      this.$emit("subtableShowRowBtnClick", row);
    },
    applyGlobalSetings(data) {
      if (data !== undefined && data.headerHeight !== undefined) {
        this.globalSettings.headerHeight = data.headerHeight;
      }
      if (data !== undefined && data.sideBar !== undefined) {
        this.sideBar = data.sideBar;
      }
      if (data !== undefined && data.height !== undefined) {
        this.documentHeight = data.height;
      } else {
        this.documentHeight = window.innerHeight;
      }
      if (this.height) {
        this.documentHeight = this.height;
      }

      if (data !== undefined && data.groupIncludeTotalFooter !== undefined) {
        this.globalSettings.groupIncludeTotalFooter =
          data.groupIncludeTotalFooter;
      }
      if (data !== undefined && data.groupIncludeFooter !== undefined) {
        this.globalSettings.groupIncludeFooter = data.groupIncludeFooter;
      }
      if (data !== undefined && data.showGridControll !== undefined) {
        this.globalSettings.showGridControll = data.showGridControll;
      }
      if (data !== undefined && data.defaultColDef !== undefined) {
        Object.keys(data.defaultColDef).map((key) => {
          this.defaultColDef[key] = data.defaultColDef[key];
        });
      }
      if (data !== undefined && data.domLayout !== undefined) {
        this.domLayout = data.domLayout;
        this.documentHeight = null;
      }
    },
  },
  beforeMount: function () {
    if (this.tableType) {
      this.gridOptions.context.tabletype = this.tableType;
    }
    if (this.entityPermission) {
      this.gridOptions.context.permission = this.entityPermission;
    }
    this.applyGlobalSetings(this.settings);
  },
  mounted() {
    this.lastPage = this.startRowsLoaded;
  },
  created() {
    this.getRowId = (params) => {
      return params.data.id ?? Math.floor(Math.random() * 1000000);
    };
  },
};
</script>
<style lang="scss">
@import "~ag-grid-community/styles/ag-grid.css";
@import "~ag-grid-community/styles/ag-theme-material.css";

.scroll {
  overflow: auto;
}
// .ag-body-horizontal-scroll-viewport::-webkit-scrollbar,
// .ag-body-viewport::-webkit-scrollbar,
// .scroll::-webkit-scrollbar {
//   -webkit-appearance: none;
// }
.ag-body-horizontal-scroll-viewport::-webkit-scrollbar:vertical,
.ag-body-viewport::-webkit-scrollbar:vertical,
.scroll::-webkit-scrollbar:vertical {
  width: 0.5rem;
}

.ag-body-horizontal-scroll-viewport::-webkit-scrollbar:horizontal,
.ag-body-viewport::-webkit-scrollbar:horizontal,
.scroll::-webkit-scrollbar:horizontal {
  height: 0.5rem;
}
.ag-body-horizontal-scroll-viewport:hover::-webkit-scrollbar:vertical,
.ag-body-viewport:hover::-webkit-scrollbar:vertical,
.scroll:hover::-webkit-scrollbar:vertical {
  width: 0.7rem;
  cursor: grab;
}

.ag-body-horizontal-scroll-viewport:hover::-webkit-scrollbar:horizontal,
.ag-body-viewport:hover::-webkit-scrollbar:horizontal,
.scroll:hover::-webkit-scrollbar:horizontal {
  height: 0.7rem;
  cursor: grab;
}

.ag-body-horizontal-scroll-viewport::-webkit-scrollbar-track,
.ag-body-viewport::-webkit-scrollbar-track,
.scroll::-webkit-scrollbar-track {
  background-color: transparent;
}
.ag-body-horizontal-scroll-viewport::-webkit-scrollbar-thumb,
.ag-body-viewport::-webkit-scrollbar-thumb,
.ag-ltr .ag-cell-focus:not(.ag-cell-range-selected):focus-within,
.ag-ltr .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected),
.ag-ltr .ag-full-width-row.ag-row-focus:focus .ag-cell-wrapper.ag-row-group,
.ag-ltr .ag-cell-range-single-cell,
.ag-ltr .ag-cell-range-single-cell.ag-cell-range-handle,
.ag-rtl .ag-cell-focus:not(.ag-cell-range-selected):focus-within,
.ag-rtl .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected),
.ag-rtl .ag-full-width-row.ag-row-focus:focus .ag-cell-wrapper.ag-row-group,
.ag-rtl .ag-cell-range-single-cell,
.ag-rtl .ag-cell-range-single-cell.ag-cell-range-handle {
  border: none;
}
.scroll::-webkit-scrollbar-thumb {
  box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
  border-radius: 100px;
  background: grey;
  border-left: 0.1rem solid transparent;
  border-right: 0.1rem solid transparent;
  background-clip: padding-box;
}

.grid-controll {
  position: relative;
}
.grid-controll-bottom {
  position: absolute;
  bottom: 20px;
  left: 10px;

  .grid-controll-bottom__wrap {
    span {
      padding: 2px 2px;
      color: #000;
      font-size: 12px;
      // background-color: rgb(228, 228, 228);
      border: 1px solid #998;
      border-radius: 25px;
      border-color: rgb(23, 22, 22);
      padding-bottom: 6px;
      opacity: 0.5;
      &:hover {
        color: #999;
      }
      svg {
        height: 20px;
        cursor: pointer;
      }
      &:hover {
        color: #999;
      }
    }
  }
}
.control-item {
  margin-left: 10px;
  /*margin-bottom: 10px;*/
  font-size: 13px;
  cursor: pointer;
  color: rgb(53, 159, 247);
}
.control-item:hover {
  color: rgb(3, 40, 145);
}

.ag-theme-material .ag-status-bar {
  border: none;
  border-color: none;
}
.ag-header-cell,
.ag-header-group-cell {
  padding-left: 5px;
  padding-right: 5px;
}
// .ag-header-cell-label {
//   justify-content: center;
// }
.ag-cell {
  padding-left: 5px;
  padding-right: 5px;
}
.ag-cell > span {
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
