<template>
  <div class="v-locale-provider v-locale--is-ltr">
    <div
      v-if="$route.meta.template == 'landing'"
      class="v-application v-theme--BLUE_THEME v-layout v-layout--full-height v-locale--is-ltr"
    >
      <router-view />
    </div>
    <div
      v-else
      class="v-application v-theme--BLUE_THEME v-layout v-layout--full-height v-locale--is-ltr BLUE_THEME horizontalLayout inputWithbg"
      style="z-index: 1000; width: 100dvw"
    >
      <div class="v-application__wrap">
        <page-header v-if="token"></page-header>
        <main
          class="v-main main-content d-flex flex-column"
          style="padding-bottom: 10px"
        >
          <div
            class="v-container v-container--fluid"
            style="
              display: flex;
              flex-direction: column;
              padding: 0px;
              max-width: 100dvw;
              width: 100%;
              flex: 1 0 auto;
            "
          >
            <router-view />
          </div>
        </main>
      </div>
    </div>
  </div>
  <div class="v-overlay-container" id="v-overlay-container"></div>
  <div class="dialog-container" id="dialog-container"></div>
  <NotificationGroup group="alert">
    <div
      class="fixed inset-0 flex items-start justify-end p-6 px-4 py-6 pointer-events-none"
      style="z-index: 90000"
    >
      <div class="w-full max-w-sm">
        <Notification
          v-slot="{ notifications }"
          enter="transform ease-out duration-300 transition"
          enter-from="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-4"
          enter-to="translate-y-0 opacity-100 sm:translate-x-0"
          leave="transition ease-in duration-500"
          leave-from="opacity-100"
          leave-to="opacity-0"
          move="transition duration-500"
          move-delay="delay-300"
        >
          <alert-item
            v-for="notification in notifications"
            :key="notification.id"
            :item="notification"
          ></alert-item>
        </Notification>
      </div>
    </div>
  </NotificationGroup>
</template>

<script>
import PageHeader from "@/components/header/PageHeader";
import AlertItem from "@/components/AlertItem.vue";
import { useUserStore } from "@/stores/userStore";
import { mapState, mapActions } from "pinia";

export default {
  name: "App",
  components: {
    PageHeader,
    AlertItem,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(useUserStore, ["token"]),
  },
  mounted() {},
  methods: {
    ...mapActions(useUserStore, ["checkEntities"]),
  },
  created() {
    this.checkEntities();
  },
};
</script>
<style lang="scss">
@import "./assets/styles/main.scss";

.main-content {
  padding-top: 70px;
}
.max-w-sm {
  max-width: 24rem;
}

.page-wrapper {
  padding: 0px 0px 0px 0px !important;
}

.v-card-text {
  background: white;
  letter-spacing: 0.0178571429em;
  padding: 7px 5px 7px 8px;
  text-transform: none;
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e2e8f0;
  /*margin: 5px;*/
}

.v-card-item .v-card-title {
  padding: 7px 22px 4px 2px;
}
</style>
